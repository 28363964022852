export default {
  // common label
  'label.type': 'Type',
  'label.name': 'Name',
  'label.content': 'Content',
  'label.remark': 'Remark',
  'label.createTime': 'Create Time',
  'label.operation': 'Action',
  'label.code': 'Code',
  'label.date': 'Date',
  'label.title': 'Title',
  'label.status': 'Status',
  'label.department': 'Department',
  'label.endpoint': 'Endpoint',
  'label.username': 'Username',
  'label.unitName': 'UnitName',
  'label.UID': 'UID',
  'label.email': 'Email',
  'label.mobile': 'Mobile',
  'label.yes': 'Yes',
  'label.no': 'No',
  'label.logout': "Logout",
  'label.import': 'Import',
  'label.export': 'Export',
  'label.delete': 'Delete',
  'label.BasicInformation':"BasicInformation",
  'label.NodeName':"NodeName",
  'label.SerialNumber':"SerialNumber",
  'label.CustomsTariff':"CustomsTariff",
  'label.Notes':"Notes",
  'label.RuleInformation':"Rule Information",
  'label.RuleName':"Rule Name",
  'label.UpdateTime':"Update Time",
  'label.Status':"Status",
  'label.RuleStatus':'Rule Status',
  'label.RuleNumber':'Rule Number',
  'label.RuleConfiguration':"Rule Configuration",
  'label.ConditionType':'Condition Type',
  'label.SelectParameter':"Select Parameter",
  'label.SelectLogic':"Select Logic",
  'label.InputCondition':"Input Condition",
  'label.InputConditionValue':"Input Condition Value",
  'label.Classification':"Classification",
  // common button
  'button.search': 'Search',
  'button.reset': 'Reset',
  'button.cancel': 'Cancel',
  'button.save': 'Save',
  'button.import': 'Import',
  'button.export': 'Export',
  'button.add': 'Add',
  'button.submit': 'Submit',
  'button.ok': 'OK',
  'button.synchronize': 'Synchronize',

  // common message
  'message.add': 'Add Successfully.',
  'message.edit': 'Save Successfully.',
  'message.delete.confirm': 'Are you sure to delete this record?',
  'message.delete.success': 'Delete record success.',
  'message.rule': 'field format is wrong。',
  'message.read.success': 'Operate Successful.',
  'message.async.title': 'Are you sure to synchronize permissions?',
  'message.async.success': 'Synchronize Successful',
  'message.back': 'Back to home',

  // table label
  'label.table.title': 'The result include',
  'label.table.items': 'items',
  'label.table.edit': 'Edit',
  'label.table.delete': 'Delete',
  'label.table.detail': 'Detail',
  'label.table.permission': 'Permission',

  // placeholder
  'placeholder.select': 'Please Select',
  'placeholder.input': 'Please Input',

  // Audit
  'label.audit.detail': 'Audit Detail',

  // Delegation
  'label.delegation.id': 'Delegate UID',
  'label.delegation.name': 'Delegate Name',
  'label.delegation.list': 'Delegate List',
  'label.delegation.startDate': 'Start Date',
  'label.delegation.endDate': 'End Date',
  'label.delegation.add': 'Add Delegation',
  'label.delegation.edit': 'Edit Delegation',

  'label.delegation.authorizer.id': 'Authorizer UID',
  'label.delegation.authorizer.name': 'Authorizer  Name',

  'rule.delegation.id': 'Delegation id can not be empty!',
  'rule.delegation.startTime': 'Start time can not be empty!',
  'rule.delegation.endTime': 'End time can not be empty!',

  // Dict
  'label.dict.table': 'Dict table',
  'label.dict.key': 'Key',
  'label.dict.cn': 'CN',
  'label.dict.en': 'EN',
  'label.dict.add': 'Add Dict',
  'label.dict.edit': 'Edit Dict',

  'rule.dict.code': 'Please input dict code',
  'rule.dict.name': 'Please input dict name',

  // Notification
  'label.notification.handle': 'Link',
  'label.notification.ignore': 'Mark read',
  'label.notification.read': 'Read',
  'label.notification.unread': 'Unread',
  'label.notification.clear': 'All Read',
  'label.notification.more': 'More',

  // Department
  'label.department.title': 'View Department',
  'label.department.unit.name': 'Unit name',
  'label.department.unit.id': 'Unit id',
  'label.department.short.name': 'Short name',
  'label.department.parent.id': 'Parent id',

  // permission
  'label.permission.name': 'Permission Name',
  'label.permission.code': 'Permission Code',
  'label.permission.action': 'Permission Action',
  'label.permission.data': 'Data Permissions',
  'label.permission.title': 'Permissions',
  'label.permission.description': 'Permission Description',

  'column.title':'xiugai',
  // Role
  'label.role.code': 'Role Code',
  'label.role.name': 'Role Name',
  'label.role.isGlobal': 'Global Role',
  'label.role.enabled': 'Enabled',
  'label.role.disabled': 'Disabled',
  'label.role.description': 'Role Description',
  'label.role.add': 'Add role',
  'label.role.edit': 'Edit role',
  'label.role.permission': 'Edit permission',

  'rule.role.code': 'Role code can not be empty!',
  'rule.role.name': 'Role name can not be empty!',
  'rule.role.model': 'Company can not be empty!',

    // user
    'label.user.add': 'Add user',
    'label.user.edit': 'Edit user',
    'label.user.roles': 'Role List',
  
    'rule.user.name': 'Username can not be empty!',
    'rule.user.uid': 'Uid can not be empty!',
    'rule.user.deptCode': 'DepartmentName can not be empty!',
    'rule.user.email': 'Email can not be empty!',
    'rule.user.email.type': 'Incorrect email format!',
    'rule.user.mobile': 'Mobile can not be empty!',
    'rule.user.mobile.type': 'Incorrect phone number format!',

  // container
  'label.menu.search': 'Query Conditions',
  'label.menu.table': 'Query Result',
  'label.menu.close': 'Close',
  'label.menu.open': 'Expand',

  // menu
  'menu.home': 'Home',
  'menu.system': 'HS-code',
  'menu.system.rbac': 'RBAC',
  'menu.rbac.user': 'User',
  'menu.library.cls': 'FV-hscode',
  'menu.rbac.role': 'Role',
  'menu.rbac.permission': 'Permission',
  'menu.rbac.department': 'Department',
  'menu.system.notification': 'Notification',
  'menu.system.dictionary': 'Data Dictionary',
  'menu.system.audit': 'Audit',
  'menu.system.delegation': 'Delegation',
  'menu.system.notFound': 'Not Found',
};

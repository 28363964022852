export default {
  // common label
  'label.type': '类型',
  'label.name': '名称',
  'label.content': '内容',
  'label.remark': '备注',
  'label.createTime': '创建时间',
  'label.operation': '操作',
  'label.code': '编码',
  'label.date': '日期',
  'label.title': '标题',
  'label.status': '状态',
  'label.department': '部门',
  'label.endpoint': '终端类型',
  'label.username': '用户名',
  'label.unitName': '部门名称',
  'label.UID': 'UID',
  'label.email': '邮箱',
  'label.mobile': '手机号',
  'label.yes': '是',
  'label.no': '否',
  'label.logout': "退出",
  'label.import': '导入',
  'label.export': '导出',
  'label.delete': '删除',
  'label.BasicInformation':"基础信息",
  'label.NodeName':"节点名",
  'label.SerialNumber':"序号",
  'label.CustomsTariff':"税则",
  'label.Notes':"备注",
  'label.RuleInformation':"规则信息",
  'label.RuleName':"规则名称",
  'label.UpdateTime':"更新时间",
  'label.Status':"状态",
  'label.RuleStatus':'规则状态',
  'label.RuleNumber':'规则序号',
  'label.RuleConfiguration':"规则配置",
  'label.ConditionType':'条件类型',
  'label.SelectParameter':"选择指标",
  'label.SelectLogic':"选择逻辑",
  'label.InputCondition':"输入条件",
  'label.InputConditionValue':"输入条件值",
  'label.Classification':"商品归类",
  // common button
  'button.search': '搜索',
  'button.reset': '重置',
  'button.cancel': '取消',
  'button.save': '保存',
  'button.add': '添加',
  'button.import': '导入',
  'button.export': '导出',
  'button.ok': '确定',
  'button.synchronize': '同步',
  'button.submit': '提交',

  // common message
  'message.add': '添加成功',
  'message.edit': '编辑成功',
  'message.delete.confirm': '确认要删除该记录吗？',
  'message.delete.success': '删除记录成功。',
  'message.rule': '当前信息格式不正确。',
  'message.read.success': '操作成功',
  'message.async.title': '确认要同步权限吗？',
  'message.async.success': '权限同步成功',
  'message.back': '返回首页',

  // table label
  'label.table.title': '结果包含',
  'label.table.items': '项',
  'label.table.edit': '编辑',
  'label.table.delete': '删除',
  'label.table.detail': '详情',
  'label.table.permission': '权限',

  // placeholder
  'placeholder.select': '请选择',
  'placeholder.input': '请输入',

  // Audit
  'label.audit.detail': '审计日志详情',

  // Delegation
  'label.delegation.id': '代理人UID',
  'label.delegation.name': '代理人姓名',
  'label.delegation.list': '代理人列表',
  'label.delegation.startDate': '开始日期',
  'label.delegation.endDate': '结束日期',
  'label.delegation.add': '添加代理',
  'label.delegation.edit': '编辑代理',

  'label.delegation.authorizer.id': '授权人工号',
  'label.delegation.authorizer.name': '授权人姓名',

  'rule.delegation.id': '代理人工号不能为空！',
  'rule.delegation.startTime': '开始时间不能为空！',
  'rule.delegation.endTime': '结束时间不能为空！',

  // Dict
  'label.dict.table': '字典表',
  'label.dict.key': '主键',
  'label.dict.cn': '中文',
  'label.dict.en': '英文',
  'label.dict.add': '添加字典',
  'label.dict.edit': '编辑字典',

  'rule.dict.code': '请输入字典编码',
  'rule.dict.name': '请输入字典名称',

  // Notification
  'label.notification.handle': '处理',
  'label.notification.ignore': '已读',
  'label.notification.read': '已读',
  'label.notification.unread': '未读',
  'label.notification.clear': '清空通知',
  'label.notification.more': '查看更多',

  // Department
  'label.department.title': '查看部门信息',
  'label.department.unit.name': '部门名称',
  'label.department.unit.id': '部门编号',
  'label.department.short.name': '部门简称',
  'label.department.parent.id': '父级部门',

  // permission
  'label.permission.name': '权限名称',
  'label.permission.code': '权限编码',
  'label.permission.action': '权限动作',
  'label.permission.data': '数据权限',
  'label.permission.title': '权限',
  'label.permission.description': '权限描述',
//
'column.title':'修改',
  // Role
  'label.role.code': '角色编码',
  'label.role.name': '角色名称',
  'label.role.isGlobal': '全局角色',
  'label.role.enabled': '启用',
  'label.role.disabled': '禁用',
  'label.role.description': '角色描述',
  'label.role.add': '新增角色',
  'label.role.edit': '编辑角色',
  'label.role.permission': '编辑权限',
 
  'rule.role.code': '角色编码不能为空！',
  'rule.role.name': '角色名称不能为空！',
  'rule.role.model': '所在企业不能为空！',
 // user
 'label.user.add': '新增用户',
 'label.user.edit': '修改用户',
 'label.user.roles': '角色列表',

 'rule.user.name': '用户名不能为空！',
 'rule.user.uid': 'uid不能为空！',
 'rule.user.deptCode': '部门名称不能为空！',
 'rule.user.email': 'email不能为空！',
 'rule.user.email.type': 'email格式不正确！',
 'rule.user.mobile': '手机号不能为空！',
 'rule.user.mobile.type': '手机号格式不正确！',

  // container
  'label.menu.search': '查询条件',
  'label.menu.table': '结果展示',
  'label.menu.close': '关闭',
  'label.menu.open': '展开',

  // menu
  'menu.home': '首页',
  'menu.system': '归类模块',
  'menu.system.rbac': '权限系统',
  'menu.rbac.user': '用户管理',
  'menu.library.cls': '智能归类',
  'menu.rbac.role': '角色管理',
  'menu.rbac.permission': '权限管理',
  'menu.rbac.department': '部门管理',
  'menu.system.notification': '通知管理',
  'menu.system.dictionary': '数据字典',
  'menu.system.audit': '审计日志',
  'menu.system.delegation': '代理授权',
  'menu.system.notFound': '对不起，你访问的页面不存在',
};
